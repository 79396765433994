<template>
    <div id="app">
        <router-view v-if="ready" :key="$route.fullPath" />

		<b-modal
			ref="toPayOrdersModal"
			centered
			:title="`Order${toPayOrders.length ? 's' : ''} to Pay`"
		>
			<div class="d-block text-center">
				<p>You have order<span v-if="toPayOrders.length">s</span> that <span v-if="toPayOrders.length > 1">are</span><span v-else>is</span> not yet paid. To pay, simply go to your <router-link to="/account" class="text-danger">Account</router-link> and select Orders tab. Go to the order with "To Pay" status and click "Proceed to Payment Gateway" button.</p>
			</div>
		</b-modal>

        <SessionModal 
            ref="sessionModal"
            v-on:continue-session="authenticateUser"
            v-on:cancel-session="signOut"
        />
        
        <!-- <AllowLocation ref="allowLocation" /> -->
        <!-- <EcqPopup /> -->
    </div>
</template>

<script>
import firebase from "firebase";
import { mapActions, mapGetters } from "vuex";
import { api } from "../src/config"
import SessionModal from "./components/Modals/SessionModal"
// import EcqPopup from './components/Modals/EcqPopup'
// import AllowLocation from './components/Modals/AllowLocation'

const _tempFetch = window.fetch
let sessionInterval;

export default {
    components: {
        SessionModal,
        // EcqPopup,
        // AllowLocation
    },
    data: () => ({
        user: null,
		ready: false,
        toPayOrders: [],
    }),
    metaInfo() {
        return {
            title: "Transcycle Online Store",
            meta: [
                {
                    name: "description",
                    content:
                        "Transcycle's goal is bringing the best possible shopping experience to any enthusiast who visits us in-store or online looking for motorcycles, apparels, helmets, accessories and genuine parts.",
                },
                { property: "og:title", content: "Transcycle Online Store" },
                {
                    property: "og:site_name",
                    content: "Transcycle Online Store",
                },
                { property: "og:type", content: "website" },
                { name: "robots", content: "index,follow" },
            ],
        };
    },
    computed: {
        ...mapGetters({
            loggedIn: "loggedIn",
            token: "token",
            expirationTime: "expirationTime",
			order: "Cart/order",
			initializing: "Cart/initializing"
        }),
    },
    beforeCreate() {
        let self = this;

        window.fetch = (url, options) => {
            if(self.loggedIn) {
                clearInterval(sessionInterval);
                sessionInterval = setInterval(self.showSessionModal, process.env.VUE_APP_DEF_SESSION_TIMEOUT)
            }

            return _tempFetch(url, options)
        }
    },
    async mounted() {
        const self = this
        await this.$gmapApiPromiseLazy(); //initalize google map api (can be access true window.google)
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                this.user = user;
                self.fetchUser(user)
                let { expirationTime, token } = await user.getIdTokenResult()
                this.setToken(token);
                this.setExpirationTime(expirationTime);
            } else {
                clearInterval(sessionInterval);
            }

            this.$eventBus.$emit("initialized");

			this.ready = true;
			
			//Uncomment to show orders not yet paid
			//this.getOrders(user.uid)
        });
        
        this.setUserLocation();
    },
    methods: {
        ...mapActions({
            setToken: "setToken",
            setExpirationTime: "setExpirationTime",
            fetchUser: "fetchUser",
            getOrder: "Cart/getOrder",
            logout: "logout",
            setUserLocation: "UserLocation/setUserLocation"
        }),
        authenticateUser() {
			let self = this;
			let currentUser = firebase.auth().currentUser

			if(!currentUser) return

			currentUser.getIdTokenResult(true)
				.then(({ expirationTime, token}) => {
					self.setToken(token);
                    self.setExpirationTime(expirationTime);
				})
				.catch(() => {
					self.signOut()
				});
        },
        signOut(timeOut) {
            let query = {}

            if(timeOut) query = { 
                sessionTimeout: true,
                redirectURL: this.$route.path
            }

			firebase
				.auth()
				.signOut()
				.then(() => {
					this.$router.push({
                        path: "/login",
                        query
					});
				});
			
			this.user = null;
			this.setToken(null);
            this.setExpirationTime(null);
			this.logout()
			clearInterval(sessionInterval);
        },
        showSessionModal() {
			// let expirationTime = Date.parse(this.expirationTime) //convert Expiration time UTC string to Date
			// const currentTime = Date.now()
			// expirationTime = expirationTime - (300000)
			
			// if(expirationTime < currentTime) {
			// 	this.$refs.sessionTimeOutModal.show();
            // }
            this.$refs.sessionModal.$refs.sessionTimeOutModal.show()
            // console.log("Logged after calling fetch api ", sessionInterval )
            // console.log(self.$router.currentRoute)
        },
		async getOrders(userId) {
			this.applications = [];
			let request = await fetch(`${api}/customer/${userId}/orders?status=To Pay`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

            let response = await request.json();
			this.toPayOrders = response.rows

			if(this.toPayOrders.length) {
				this.$refs.toPayOrdersModal.show();
			}
		}
    },
    watch: {
        token(after) {
            if (after) {
                if(!this.initializing) {
					this.fetchUser(this.user);
					this.getOrder(this.$route.params.orderId);
				}
            }
		},
    },
};
</script>
